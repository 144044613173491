import { graphql } from 'gatsby'
import { Container } from '@chakra-ui/react'
import { TextPage } from '@components/TextPage'

const LitUpTermsPage = ({ data }) => {
  return (
    <Container maxW="container.lg" py={24}>
      <TextPage html={data.litUpJson.terms.childMarkdownRemark.html} />
    </Container>
  )
}

export default LitUpTermsPage

export const query = graphql`
  query LitUpTermsQuery {
    litUpJson {
      terms {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
